import { gsap } from "gsap";
import React, { useContext, useLayoutEffect, useRef } from "react";
import { PopupContext } from "../../../store/DemoForm-context";
import styles from "./TopBanner.module.css";
export const TopBanner = () => {
  const { showPopup } = useContext(PopupContext);

  const app = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let targets = gsap.utils.toArray(".Textchange");
      gsap.set(targets, { autoAlpha: 1 });
      let dur = 0.5;
      let hold = 2;

      targets.forEach((obj, i) => {
        let tl = gsap.timeline({
          delay: dur * i + hold * i,
          repeat: -1,
          repeatDelay: (targets.length - 1) * (dur + hold) - dur,
          defaults: {
            ease: "none",
            duration: dur,
          },
        });
        tl.from(obj, { yPercent: -60, opacity: 0 });
        tl.to(obj, { yPercent: 60, opacity: 0 }, "+=" + hold);
      });

      gsap.from(".bannerTop", {
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".topBannerAni h1", {
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".topBannerAni p", {
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });

      gsap.from(".topBannerAni .bannerList", {
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".topBannerAni .callAction", {
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".clientOne li", {
        scrollTrigger: {
          trigger: ".clientOne li",
          start: 200,
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: { each: 0.25 },
      });
      gsap.from(".clientTwo li", {
        scrollTrigger: {
          trigger: ".clientTwo li",
          start: 200,
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: { each: 0.25 },
      });
    }, app);
    return () => ctx.revert();
  }, []);
  return (
    <section className={styles.topBanner}>
      <div className="container">
        {/* <Proud/> */}
        <div className={styles.bannertBox} ref={app}>
          <div className={`${styles.bannerContent} topBannerAni`}>
            {/* <div className={`${styles.sellFast} poppinsbold lineHeight56`}><span>Sell Fast, Sell Live</span></div> */}
            <h1 className={`${styles.topBannerHeading}  poppinsbold`}>
              Go live with
              <br />
              <span className={styles.animateText}>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Commerce
                </span>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Selling
                </span>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Assistance
                </span>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Tiles
                </span>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Feedbacks
                </span>
                <span className={`Textchange ${styles.Textchange}`}>
                  Video Shopping
                </span>
              </span>
              <br />
              in 2 minutes
            </h1>
            <p className="text122D font16 mb20 poppinsmedium topSubani lineHeight26">
              Use Video commerce to turn your static website into a dynamic
              <br />& engaging buying experience that your customers will love.
            </p>
            <ul className={`${styles.bannerList} bannerList mb25`}>
              <li>30% boost in sales</li>
              <li>2X Engagement</li>
              <li>1.5X lead capture</li>
            </ul>
            <div className="btnBox callAction">
              <button
                className={`btn ${styles["cta-demo"]}`}
                onClick={showPopup}
              >
                <div>
                  <span>Book A Demo</span>
                  <span>Book A Demo</span>
                </div>
              </button>
            </div>
          </div>
          <div className={styles.bannerImage}>
            <video
              width="577"
              height="444"
              muted
              id="vid"
              loop="loop"
              autoPlay="autoplay"
            >
              <source src="assets/video/hero-banner-v1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};
