import styles from './TrySaleAssist.module.css';
export const TrySaleAssist = () => {
  return (
    <>
      <section className={`trySection ${styles.sectionTry}`}>
        <div className='container'>
          <div className={styles.apiWidget}>
            <div className={styles.apiText}>
              <h4 className='font30 mb20 poppinsbold lightBlack'>Try SaleAssist for free with our API Playground</h4>
              <p className='font16 text6565 lineHeight28'>Test your own audio files or quickly explore its capabilities with our pre-recordings. Try it now for a seamless audio API experience!</p>
            </div>
              <a className='btn btn-primary btn-typ-1' href={`https://platform.saleassist.ai/redoc`} title={`Go To API Playground`}>
                <div>
                  <span>Go To API Playground</span>
                  <span>Go To API Playground</span>
                </div>
              </a>
          </div>
        </div>
      </section>
    </>
  )
}
