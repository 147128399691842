import { gsap } from "gsap";
import React, { useLayoutEffect, useRef } from "react";
import styles from "./Reimage.module.css";
export const ReImage = () => {
  const app = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(".ReImage h4", {
        scrollTrigger: {
          trigger: ".ReImage h4",
          start: 500,
          // markers:true
        },
        duration: 1,
        opacity: 0,
        delay: 0.8,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".ReImage figure", {
        scrollTrigger: {
          trigger: ".ReImage figure",
          start: 500,
        },
        duration: 1,
        opacity: 0,
        delay: 0.8,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".ReImage p", {
        scrollTrigger: {
          trigger: ".ReImage p",
          start: 500,
        },
        duration: 1,
        opacity: 0,
        delay: 1,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".ReImage ul", {
        scrollTrigger: {
          trigger: ".ReImage ul",
          start: 500,
        },
        duration: 1,
        opacity: 0,
        delay: 1.2,
        y: 200,
        stagger: 0.25,
      });
    }, app);
    return () => ctx.revert();
  }, []);
  return (
    <section className={`${styles.ReImage} ReImage`} ref={app}>
      <div className={`containerNoP `}>
        <div className={`${styles.gridgap}`}>
          <div className={styles.ReImageThumb}>
            <figure>
              <img
                src="assets/images/re-imagined-ecommerce.svg"
                className="imgResponsive"
                alt="re 1"
              />
            </figure>
          </div>
          <div className={styles.ReImageContent}>
            <h4 className="font48 poppinsbold lightBlack mb25 textCenter wsn">
              Re-Imagine E-Commerce
            </h4>
            <p className="poppinssemibold font30 mb40 lightGray textCenter">
              One Platform.
              <br /> Unlimited Potential.
            </p>
            <ul className={`checkList ${styles.listEL}`}>
              <li>Engaging customer</li>
              <li>Enhance brand perception</li>
              <li>Simple Integration</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
