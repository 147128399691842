import styles from "./TestimonialSec.module.css";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const TestimonialSec = () => {
  return (
    <>
      <section className={`${styles.testimonialSec}`}>
        <div className="container">
          <div className={styles.secHeading}>
            <h3 className={styles.headingTitle}>
              The Proof of Pudding is here
            </h3>
          </div>
          <div className={styles.TestimonialBox}>
            <div className={styles.TestimonialCard}>
              <div className={styles.TestimonialCardBox}>
                <div className={styles.TestimonialVideo}>
                  <img
                    src="/assets/images/testimonials/abraham-alapatt.png"
                    alt="abraham-alapatt"
                  />
                </div>
                <div className={styles.TestimonialsCaptions}>
                  <div className={styles.TestimonialMsg}>
                    <p>
                      Video Call initiators display a distinctive commitment,
                      seeking personalized reassurance of their travel plans.
                      This heightened engagement translates to a conversion
                      surge -
                      <span>upto three times the average conversion rate.</span>
                    </p>
                  </div>
                  <div className={styles.TestimonialProvider}>
                    <div className={styles.TestimonialsName}>
                      <h5>Abraham Alapatt</h5>
                      <p>President Marketing - Thomas Cook</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.TestimonialCard}>
              <div className={styles.TestimonialCardBox}>
                <div className={styles.TestimonialVideo}>
                  <img
                    src="/assets/images/testimonials/vikas.png"
                    alt="vikas"
                  />
                </div>
                <div className={styles.TestimonialsCaptions}>
                  <div className={styles.TestimonialMsg}>
                    <p>
                      In this initial phase of our partnership with SaleAssist,
                      one key beneﬁt is the <span>increased productivity</span>{" "}
                      of our Sales Team, which has been helped by better{" "}
                      <span>TAT for conversion</span>, and increment in the Lead
                      to Meeting ratio as opposed to an ofﬂine ﬁrst sales model.
                    </p>
                  </div>
                  <div className={styles.TestimonialProvider}>
                    <div className={styles.TestimonialsName}>
                      <h5>Vikaas</h5>
                      <p>Head Digital - Mahindra's</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.TestimonialCard}>
              <div className={styles.TestimonialCardBox}>
                <div className={styles.TestimonialVideo}>
                  <img
                    src="/assets/images/testimonials/vishal.png"
                    alt="vikas"
                  />
                </div>
                <div className={styles.TestimonialsCaptions}>
                  <div className={styles.TestimonialMsg}>
                    <p>
                      SaleAssist has lot of features like live call, video
                      feedbacks and live video shopping and this deﬁnitely gives
                      better <span>customer experience & conversions.</span>
                    </p>
                  </div>
                  <div className={styles.TestimonialProvider}>
                    <div className={styles.TestimonialsName}>
                      <h5>Vishal</h5>
                      <p>VP - Viveks</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TestimonialSec;
