import { useContext, useRef, useState } from "react";
import { config } from "../../../config";
import { postCall } from "../../../helpers/Api";
import styles from "./Book-demo.module.css";

import { PopupContext } from "../../../store/DemoForm-context";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BookDemo = (props) => {
  const [demoThanks, setDemoThanks] = useState(false);
  const { isPopupVisible, hidePopup } = useContext(PopupContext);
  const [phone, setPhone] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);  
  const [companyError, setCompanyError] = useState(false);


  const clearUserInfo = () => {
    userEmail.current.value = "";
    userPhone.current.value = "";
    setPhone("");
  };

  const closeModal = () => {
    hidePopup();
    setDemoThanks(false)
  };

  const gtagSendEvent = (url) => {
      let callback = function () {
        if (typeof url === 'string') {
          window.location = url;
        }
      };
      window.gtag('event', 'conversion_event_submit_lead_form', {
        'event_callback': callback,
        'event_timeout': 2000,
        // <event_parameters>
      });
      return false;    
  }


  const userEmail = useRef();
  const userPhone = useRef();
  const company = useRef();

  const handleDemoFormSubmit = (e) => {
    e.preventDefault();

    let inputEmail = userEmail.current.value;
    let emailPattern =  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!emailPattern.test(inputEmail)) {
      setEmailError(true);
      return false
    }
    setEmailError(false);

    let inputNum = phone;
    if(inputNum.match(/12345/) || inputNum.length < 12) {
      setNumberError(true);
      return false
    }
    setNumberError(false);

    let inputCommapny = company.current.value;
    let companyPattern = /^[a-zA-Z0-9-@.{}#&!()]+(\s[a-zA-Z0-9-@{}.#&!()]+)+(\s[a-zA-Z-@.#&!()]+)?$/;

    if (!companyPattern.test(inputCommapny)) {
      setCompanyError(true);
      return false
    }
    setCompanyError(false);

    const userInfo = {
      email: inputEmail,
      mobile: inputNum,
      company: inputCommapny,
    };


    postCall({
      url: config.url.INCOMING_WEBHOOKS,
      path: "/form",
      data: {
        form_data: userInfo,
        title: "Demo request",
      },
      onSuccess: (response) => {
        clearUserInfo();
        setDemoThanks(true);
        const pageURL = window.location.href
        gtagSendEvent(pageURL)

        setTimeout(() => {
          closeModal()
        }, 2000);
      },
    });


  };

  if (!isPopupVisible) {
    return null;
  }
  return (
    <div className={styles.modal}>
      <div className={styles.modalBody}>
        <button className={styles.closeModal} onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.261"
            height="14.261"
            viewBox="0 0 14.261 14.261"
          >
            <g>
              <path
                d="M13.559 0a.7.7 0 0 1 .492 1.189L1.183 14.054a.7.7 0 0 1-.983-.985L13.066.2a.694.694 0 0 1 .493-.2z"
                transform="translate(.006 .003)"
                fill="#000"
              />
              <path
                d="M.693 0a.694.694 0 0 1 .492.2l12.869 12.869a.7.7 0 0 1-.985.985L.2 1.185A.7.7 0 0 1 .693 0z"
                transform="translate(.003 .003)"
                fill="#000"
              />
            </g>
          </svg>
        </button>
        <div className={styles.demoModalBox}>
          <div className={styles.demoModalThumb}>
            <img src="/assets/images/demoModal-Figure.png" alt="convert text" />
          </div>
          <div className={styles.demoModalCont}>
            {!demoThanks && (
              <div className={styles.demoUserForm}>
                <div className={styles.demoModalHeading}>
                  <h3>
                    <span className="HedingBadge">Convert 30%</span>
                    <br />
                    More With Live Video Commerce
                  </h3>
                </div>
                <form
                  className={styles.demoForm}
                  onSubmit={handleDemoFormSubmit}
                >
                  {
                    emailError && (<p className={styles.errorMessage} >Please enter valid email address.</p>)
                  }
                  {
                    numberError && (<p className={styles.errorMessage} >Please enter valid mobile number.</p>)
                  }
                  {
                    companyError && (<p className={styles.errorMessage} >Please enter valid company name.</p>)
                  }
                  <div className={styles.formControl}>
                    <input
                      type="email"
                      placeholder="Business email"                
                      className={`${styles.formInput} ${emailError ? styles.inputError : ''}`}
                      ref={userEmail}
                      required
                    />
                  </div>
                  <div className={`${styles.formControl}`}>
                    <PhoneInput
                      country={"in"}
                      ref={userPhone}
                      enableSearch={false}
                      value={phone}
                      onChange={(phone) => {
                        setPhone(phone)
                      }}
                      className={`${styles.formInput} nunber-input-1 ${numberError ? styles.inputError : ''}`}
                      prefix={"+"}
                      required
                      inputProps={{
                        name: 'phone',
                        required: true,
                       }}
                    />
                    {/* <input
                      type="number"
                      placeholder="Mobile number"
                      className={styles.formInput}
                      ref={userPhone}
                      title="please enter number only"
                      pattern="[0-9]{10}"
                      minLength={10}
                      maxlength={10}
                      required
                    /> */}
                  </div>
                  <div className={styles.formControl}>
                    <input
                      type="text"
                      placeholder="Company Name"
                      className={`${styles.formInput} ${companyError ? styles.inputError : ''}`}
                      ref={company}
                      minLength={5}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-black btn-typ-1 w-100"
                  >
                    <div>
                      <span>Request A Demo</span>
                      <span>Request A Demo</span>
                    </div>
                  </button>
                </form>
              </div>
            )}
            {demoThanks && (
              <div className={styles.demoThanks}>
                <div className={styles.thanksIcon}>
                  <em>
                    <img
                      src="/assets/images/icons/ic-thanks.svg"
                      alt="icon thanks"
                    />
                  </em>
                </div>
                <div className={styles.demoModalHeading}>
                  <h3>
                    <span className={styles.HeadingImp}>Thank You!</span>
                  </h3>
                  <p>
                  We have received your submission and we will reach out to you soon.
                  </p>
                </div>
                <div className={styles.redirectBtn}>
                  <button
                    type="submit"
                    className="btn btn-black btn-typ-1 w-100"
                    onClick={closeModal}
                  >
                    <div>
                      <span>Exit!</span>
                      <span>Exit!</span>
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
