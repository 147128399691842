import { useState } from "react";
import styles from "./CommerceAiSec.module.css";
const CommerceAiSec = () => {
  const [currActive, setCurrActive] = useState(null);
  const activeHover = (item) => {
    setCurrActive(item);
  };
  const removeHover = () => {
    setCurrActive(null);
  };
  return (
    <section className={styles["hm-commerce"]}>
      <div className="container">
        <div className={styles.secHeading}>
          <h3 className={styles.headingTitle}>
            Video Commerce by <mark>AI</mark>
          </h3>
        </div>
        <div className={styles.commerceWrapper}>
          <div className={styles.commerceBox}>
            <div className={styles.commerceFeature}>
              <div className={styles.leftCircle}>
                <div
                  className={`${styles.featureList} ${
                    currActive === 1
                      ? styles.focused
                      : currActive !== 1 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(1)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Noise Suppression</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      AI-powered clarity for focused live shopping experiences.
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.featureList} ${
                    currActive === 2
                      ? styles.focused
                      : currActive !== 2 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(2)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Chat</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      Engage customers in human like natural chat format in
                      multilingual langauges.
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.featureList} ${
                    currActive === 3
                      ? styles.focused
                      : currActive !== 3 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(3)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Video</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      Generate AI text to video personalized video messages.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.rightCircle}>
                <div
                  className={`${styles.featureList} ${
                    currActive === 4
                      ? styles.focused
                      : currActive !== 4 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(4)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Adaptive Bitrate</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      AI-powered bandwidth optimization for consistent video
                      sessions.
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.featureList} ${
                    currActive === 5
                      ? styles.focused
                      : currActive !== 5 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(5)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Transcript &amp; Sentiment Analysis</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      Generate video to text and analyze each conversation & get
                      conversational insights.
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.featureList} ${
                    currActive === 6
                      ? styles.focused
                      : currActive !== 6 && currActive !== null
                      ? styles.blurred
                      : ""
                  }`}
                  onMouseEnter={() => activeHover(6)}
                  onMouseLeave={removeHover}
                >
                  <div className={styles.featureTitle}>
                    <h4 className={styles.featureName}>
                      <em></em>
                      <span>AI Virtual Background</span>
                    </h4>
                  </div>
                  <div className={styles.featurePara}>
                    <p>
                      Create immersive live shopping experiences with AI virtual
                      backgrounds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.commerceCircle}>
              <div className={styles.commerceThumb}>
                <img
                  src={`assets/images/video-commerce-thumb.png`}
                  alt="liveVideo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommerceAiSec;
