import { gsap } from "gsap";
import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SiteLogo } from "../../../ui/SiteLogo";
import styles from "./EssentialBuilding.module.css";
export const EssentialBuilding = () => {
  const app = useRef([]);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(".EssentialBuilding .containerFix", {
        scrollTrigger: {
          trigger: ".EssentialBuilding ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.4,
        y: 200,
        stagger: 0.25,
      });
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);
  return (
    <section
      className={`${styles.EssentialBuilding} EssentialBuilding`}
      useRef={app}
    >
      <div className={`container containerFlex`}>
        <div className={`containerFix ${styles.containerFix}`}>
          <div className={styles.logoEssential}>
            <Link to={"/"} relative="path">
              <SiteLogo varient="light" />
            </Link>
          </div>
          {/* <figure className={styles.logoEssential}><img src='/assets/images/logoWhite.svg'/></figure> */}
          <p className="font18 textfff">
            Live video commerce is future of selling.
          </p>
          <ul className={styles.socialIcon}>
            <li>
              <Link
                to="https://www.facebook.com/SaleAssistAI/"
                target="_blank"
              >
                <img src="/assets/images/facebook.svg" alt="soacial logo" />
              </Link>
            </li>
            <li>
              <Link to="https://x.com/salesassist_ai" target="_blank">
                <img src="/assets/images/twitter.svg" alt="soacial logo" />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/sale.assist/" target="_blank">
                <img src="/assets/images/instagram.svg" alt="soacial logo" />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/company/safemeet/"
                target="_blank"
              >
                <img src="/assets/images/linkedin.svg" alt="soacial logo" />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/channel/UCzS6BNGpNJ0OUPh60QSrXdA"
                target="_blank"
              >
                <img src="/assets/images/youtube.svg" alt="soacial logo" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
