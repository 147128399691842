import styles from "./PageLoader.module.css";

const PageLoader = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loaderBox}>
        <span>LOADING</span>
        <div className={styles.loaderBox__dots}>
          <span className={styles.loaderBox__dot} />
          <span className={styles.loaderBox__dot} />
          <span className={styles.loaderBox__dot} />
          <span className={styles.loaderBox__dot} />
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
