import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/all";
import React, { useLayoutEffect } from "react";
import styles from "./NumberSays.module.css";
export const NumberSays = () => {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
      // let elements = document.querySelectorAll(".count")
      gsap.from(".count", {
        textContent: "0",
        duration: 1,
        ease: "power1.inOut",
        modifiers: {
          textContent: (value) => formatNumber(value, 0) + "",
        },
        scrollTrigger: {
          trigger: "#fast-facts",
          start: "100px 80%",
          end: "+=100",
          toggleActions: "play none none reverse",
          markers: false,
        },
      });
      function formatNumber(value, decimals) {
        let s = (+value).toLocaleString("en-US").split(".");
        return decimals
          ? s[0] + "." + ((s[1] || "") + "00000000").substr(0, decimals)
          : s[0];
      }
      gsap.from(" .numberMain .numberBox1", {
        scrollTrigger: {
          trigger: ".numberMain ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.1,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(" .numberMain .numberBox2", {
        scrollTrigger: {
          trigger: ".numberMain ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.2,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(" .numberMain .numberBox3", {
        scrollTrigger: {
          trigger: ".numberMain ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.3,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(" .numberMain .numberBox4", {
        scrollTrigger: {
          trigger: ".numberMain ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.4,
        y: 200,
        stagger: 0.25,
      });
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);
  return (
    <section className="section bgBlack numberMain" id="fast-facts">
      <div className="container">
        <h4 className="font50 fontSM26 lineHeightSM34 textCenter poppinsbold textfff mb50">
          Number Says it all
        </h4>
        <div className={styles.numberSay}>
          <div className={`${styles.numberBox} numberBox1`}>
            <figure>
              <img
                src="/assets/images/videoSessions.png"
                className="imgResponsive"                
                alt="videoSessions"
              />
            </figure>
            <p className="poppinssemibold font115 " id="start1">
              <span className="count" id="videoSessions">
                100
              </span>
              k
            </p>
            <span className="poppinssemibold font20 fontSM16">
              Video Sessions
            </span>
          </div>
          <div className={`${styles.numberBox} numberBox2`}>
            <figure>
              <img
                src="/assets/images/increaseSales.png"
                className="imgResponsive"
                alt="increaseSales"
              />
            </figure>
            <p className="poppinssemibold font115" id="start2">
              <span className="count" id="IncreaseSales">
                30
              </span>
              %
            </p>
            <span className="poppinssemibold font20 fontSM16">
              Increase in Sales
            </span>
          </div>
          <div className={`${styles.numberBox} numberBox3`}>
            <figure>
              <img
                src="/assets/images/leadCapture.png"
                className="imgResponsive"
                alt="leadCapture"
              />
            </figure>
            <p className="poppinssemibold font115 " id="start3">
              <span className="count" id="videoSessi">
                1
              </span>
              .5x
            </p>
            <span className="poppinssemibold font20 fontSM16">
              Lead Capture
            </span>
          </div>
          <div className={`${styles.numberBox} numberBox4`}>
            <figure>
              <img
                src="/assets/images/engagement.png"
                className="imgResponsive"
                alt="engagement"
              />
            </figure>
            <p className="poppinssemibold font115" id="start4">
              <span className="count" id="engagement">
                2
              </span>
              x
            </p>
            <span className="poppinssemibold font20 fontSM16">Engagement</span>
          </div>
        </div>
      </div>
    </section>
  );
};
