import React, { useRef } from "react";
import { isMobile } from "react-device-detect";
import styles from "./EssentialBuilding.module.css";
export const EssentialBuilding1 = () => {
  const app = useRef([]);

  return (
    <section
      className={`${styles.EssentialBuilding} EssentialBuilding`}
      useRef={app}
    >
      <div className={`container containerFlex`}>
        <div className={`containerFix ${styles.containerFix}`}>
          <figure className={styles.logoEssential}>
            <img src="/assets/images/logoWhite.svg" className="imgResponsive" alt="logo white" />
          </figure>
          <p className="font22 fontSM14 textfff">
            Say Cheers to Conversions {isMobile && <br />}& turn Browsing into
            Buying
          </p>
          <ul className={styles.socialIcon}>
            <li>
              <a href="https://m.facebook.com/SaleAssistAI/">
                <img src="/assets/images/facebook.svg" alt="social logo" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/salesassist_ai">
                <img src="/assets/images/twitter.svg" alt="social logo" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sale.assist/">
                <img src="/assets/images/instagram.svg" alt="social logo" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/safemeet/mycompany/">
                <img src="/assets/images/linkedin.svg" alt="social logo" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCzS6BNGpNJ0OUPh60QSrXdA">
                <img src="/assets/images/youtube.svg" alt="social logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
