import { gsap } from "gsap";
import React, { useContext, useLayoutEffect } from "react";
import { PopupContext } from "../../../store/DemoForm-context";
import styles from "./StartToday.module.css";
export const StartToday = () => {
  const { showPopup } = useContext(PopupContext);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(" .startTodayBox .todayHeading", {
        scrollTrigger: {
          trigger: ".startTodayBox ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.2,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(" .startTodayBox .todayBtn", {
        scrollTrigger: {
          trigger: ".startTodayBox ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);

  return (
    <section className={`section bgBlack ${styles.startTodayBox}`}>
      <div className="container">
        <h3
          className={`font70 textf7e9 mb30 textCenter poppinssemibold todyBadge todayHeading`}
        >
          Start Today For Free
        </h3>
        {/* <p className='font16 textfff mb50 textCenter'>Create and publish your SaleAssist today</p> */}
        <div className="btnBox callAction dfc todayBtn">
          <button className="btn btn-primary btn-typ-1" onClick={showPopup}>
            <div>
              <span>Book A Demo</span>
              <span>Book A Demo</span>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};
