import styles from "./TopNotifications.module.css";

const TopNotifications = (props) => {
  const hideNotificationBar = () => {
    props.notificationStatus(false);
  };
  return (
    <div className={styles.topNotificationBar}>
      <div className="container">
        <div className={`${styles.notificationBox}`}>
          <div className={styles.notificationMsg}>
          🔔 SaleAssist.ai is proud to be selected for HCL Startup SYNC Retail & E-Commerce program. View details here 🎉🚀 {" "}
            <a
              href="https://www.prnewswire.com/news-releases/hclsoftwares-startup-sync-announces-first-ever-startup-cohort-program-focused-on-retail-and-e-commerce-innovation-302263034.html"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            🎉🚀
          </div>
          <button
            className={styles.notificationsCloseBtn}
            onClick={hideNotificationBar}
          >
            <img src="/assets/images/icons/ic-close.svg" alt="close icon" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default TopNotifications;
