import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../ui/ScrollToTop";
import { Footer, Header } from "../share/";

import PageLoader from "../PageLoader/PageLoader";
import BookDemo from "../share/Modal/Book-demo";
import { PopupProvider } from "./../../store/DemoForm-context";

export const Layout = () => {
  return (
    <PopupProvider>
      <Header />
      <Suspense fallback={<PageLoader />}>
        <ScrollToTop />
        <Outlet />
      </Suspense>
      <Footer />
      <BookDemo />
    </PopupProvider>
  );
};
