import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/all";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./VideoCommerceAI.module.css";
import { PopupContext } from "../../../store/DemoForm-context";
export const VideoCommerceAIActive = ({
  data,
  Heading,
  spanTag,
  subHeading,
  showBtn,
}) => {
  const app = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const acccRef = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
      let activeEl;
      let faq = gsap.utils.toArray(".service-item");
      var smallTimeline2 = new gsap.timeline({ repeat: -1, repeatDelay: 0 });

      faq.forEach((item, k) => {
        let isActive;
        const service = document.querySelector(".service-item-" + k);
        const servline = service.querySelector(".service-line");
        const servimg = service.querySelector(".service-image-" + k);
        var smallTimeline3 = new gsap.timeline();

        smallTimeline2
          .set(servline, { width: 0 })
          .from(servimg, { duration: 0.25, autoAlpha: 0 }, "-=0.5")
          .to(servline, {
            width: "100%",
            duration: 4,
            onUpdate() {
              setActiveIndex(k);
            },
          });
        smallTimeline2.progress(1);
        item._open = (active) => {
          isActive = active;
          if (active) {
            if (activeEl && activeEl !== item) {
              activeEl._open(false);
              smallTimeline2.pause();
            }
            smallTimeline2.pause();
            smallTimeline3
              .set(servline, { width: 0 })
              .from(servimg, { duration: 0.25, autoAlpha: 0 }, "-=0.5")
              .to(servline, {
                width: "100%",
                duration: 4,
                onUpdate() {
                  setActiveIndex(k);
                },
              });
            smallTimeline2.play();
            activeEl = item;
          } else {
            smallTimeline2.play();
            if (activeEl === item) {
              activeEl = null;
            }
          }
        };
        item._toggle = () => item._open(!isActive);
        item.addEventListener("click", item._toggle);
        smallTimeline2.play();
      });
      gsap.from(".videoAi h2", {
        scrollTrigger: {
          trigger: ".videoAi",
        },
        duration: 1,
        opacity: 0,
        delay: 0.3,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".videoAi .aiSub", {
        scrollTrigger: {
          trigger: ".videoAi",
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".videoAi .service-list", {
        scrollTrigger: {
          trigger: ".videoAi",
        },
        duration: 1,
        opacity: 0,
        delay: 0.7,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".videoAi .aiImageSection", {
        scrollTrigger: {
          trigger: ".videoAi",
        },
        duration: 1,
        opacity: 0,
        delay: 0.7,
        y: 200,
        stagger: 0.25,
      });

      // cleanup event listeners
      // return () => faq.forEach(item => item.removeEventListener("click", item._toggle));
    });

    return () => ctx.revert(); // <- cleanup!
  }, []);

  const { showPopup } = useContext(PopupContext)

  return (
    <>
      <section className={`${styles.videoCommerce} videoAi section`}>
        <div className="container">
          <h2
            className={`font50 mb15 textfff poppinsbold fontSM21 fontSMM17 textCenter ${styles.headingBorder}`}
          >
            {Heading || ""}{" "}
            {spanTag && <span className={styles.aiBG}>{spanTag || ""}</span>}
          </h2>
          {subHeading && (
            <p
              className={`${styles.aiSub} aiSub textCenter font18 lineHeight26 mb50`}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            ></p>
          )}
          <div className={` ${styles.aiSlideBox} ${showBtn && "mt70 mtSM30"}`}>
            {!isMobile ? (
              <ul
                className={`service-list ${styles.aiList} ${styles.aiList1}`}
                ref={acccRef}
              >
                {data.map((item, index) => (
                  <li
                    className={`${
                      activeIndex === index ? styles.active : ""
                    } service-item service-item-${index} `}
                  >
                    <span className={`service-line ${styles.loader}`}></span>
                    <h3 className="font20 poppinssemibold textfff">
                      <span className={styles.activeNumber}>{index + 1}</span>{" "}
                      {item.question}
                    </h3>
                    <div className={`service-text ${styles.serviceText1}`}>
                      <p className="font14 textfff">{item.ans}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <ul className={` ${styles.aiList} ${styles.aiList1}`}>
                {data.map((item, index) => (
                  <>
                    <li
                      className={styles.mobileLi}
                      ref={(index, element) =>
                        app.current.splice(index, 1, element).push(element)
                      }
                    >
                      <span className={`service-line ${styles.loader}`}></span>
                      <h3 className="font14 poppinssemibold textfff">
                        <span className={styles.activeNumber}>{index + 1}</span>{" "}
                        {item.question}
                      </h3>
                      <div className={`service-text ${styles.serviceText1}`}>
                        <p className="font12 textfff">{item.ans}</p>
                      </div>
                    </li>

                    <figure
                      className={`aiImageSection mb30 ${
                        styles.aiImageSection
                      } ${showBtn && styles.transparent}`}
                    >
                      {index === 0 ? (
                        <img
                          src="/assets/images/LiveVideoShopping.png"
                          className="imgResponsive"
                          alt="live video shoppinh"
                        />
                      ) : index === 1 ? (
                        <img
                          src="/assets/images/liveStream.png"
                          className="imgResponsive"
                          alt="live stram"
                        />
                      ) : index === 2 ? (
                        <img
                          src="/assets/images/shoppableVideo.png"
                          className="imgResponsive"
                          alt="shoppable video"
                        />
                      ) : (
                        ""
                      )}
                    </figure>
                  </>
                ))}
              </ul>
            )}
            {!isMobile && (
              <figure
                className={`aiImageSection ${styles.aiImageSection} ${
                  showBtn && styles.transparent
                }`}
              >
                <img
                  src="/assets/images/LiveVideoShopping.png"
                  alt="live video shopping"
                  className={`imgResponsive ${styles.imageTraction} ${
                    activeIndex === 0 ? styles.active : ""
                  }`}
                />
                <img
                  src="/assets/images/liveStream.png"
                  alt="live stram"
                  className={`imgResponsive ${styles.imageTraction} ${
                    activeIndex === 1 ? styles.active : ""
                  }`}
                />
                <img
                  src="/assets/images/shoppableVideo.png"
                  alt="shoppable video"
                  className={`imgResponsive ${styles.imageTraction} ${
                    activeIndex === 2 ? styles.active : ""
                  }`}
                />
              </figure>
            )}
          </div>
          {showBtn && (
            <div className="btnBox callAction todayBtn mt70 mtSM30 mbSM30 jfc">
              <a
                className="btn btnSecondery btnBold"
                href={`https://my.saleassist.ai/`}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <span>Start Your 14-Day Trial</span>
                  <span>Start Your 14-Day Trial</span>
                </div>
              </a>
              <button type="button" className="btn btnSeconderyOutline btnBold" onClick={showPopup}>
                <div>
                  <span>Book A Demo</span>
                  <span>Book A Demo</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
