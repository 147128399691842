import { gsap } from "gsap";
import React, { useLayoutEffect, useRef } from "react";
import { TrySaleAssist } from "./../../share";
import styles from "./Integrations.module.css";
export const Integrations = () => {
  const newInti = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(".Integrationsthat h2", {
        scrollTrigger: {
          trigger: ".Integrationsthat h2",
          // markers:true,
          // start:3500,
        },
        duration: 1,
        opacity: 0,
        delay: 0.3,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".Integrationsthat p", {
        scrollTrigger: {
          trigger: ".Integrationsthat p",
          // start:3500,
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".integrationList", {
        scrollTrigger: {
          trigger: ".integrationList",
          // start:3500,
        },
        duration: 1,
        opacity: 0,
        delay: 0.6,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(".trySection", {
        scrollTrigger: {
          trigger: ".trySection",
          // start:3500,
        },
        duration: 1,
        opacity: 0,
        delay: 0.8,
        y: 200,
        stagger: 0.25,
      });
    }, newInti);
    return () => ctx.revert();
  }, []);
  return (
    <section className="section " ref={newInti}>
      <div className={`container containerFlex ${styles.gridGap156}`}>
        <div className={`Integrationsthat ${styles.integrationText}`}>
          <h3>
            Your product, our <br />
            "Plug & Play Integration"
          </h3>
          <ul>
            <li>Frontend integration with website</li>
            <li>Backend integration with CRM</li>
            <li>Integrate in minutes, No development required</li>
          </ul>
        </div>
        <div className={` ${styles.integrationList} integrationList`}>
          <div className={styles.integrationListSubBox}>
            <div className={`${styles.listIcon1} ${styles.listIcon}`}>
              <img src="/assets/images/incone.svg" />
            </div>
            <div className={`${styles.listItem} `}>
              <img
                src="/assets/images/shopyfiy.png"
                className="imgResponsive"
                alt="shopyfiy"
              />
            </div>
            <div className={`${styles.listItem} `}>
              <img src="/assets/images/wix.png" className="imgResponsive" alt="wix" />
            </div>
            <div className={`${styles.listItem} `}>
              <img src="/assets/images/woo.png" className="imgResponsive" alt="woo" />
            </div>
          </div>
          <div className={styles.integrationListSubBox}>
            <div className={`${styles.listIcon2} ${styles.listIcon}`}>
              <img src="/assets/images/incone1.svg" alt="incone1" />
            </div>
            <div className={`${styles.listIcon3} ${styles.listIcon}`}>
              <img src="/assets/images/incone4.svg" alt="incone4" />
            </div>
            <div className={`${styles.listIcon4} ${styles.listIcon}`}>
              <img src="/assets/images/incone6.svg" alt="incone5" />
            </div>
            <div className={`${styles.listItem} `}>
              <img src="/assets/images/int4.png" className="imgResponsive" alt="int4" />
            </div>
            <div className={`${styles.listItem} `}>
              <img src="/assets/images/int5.png" className="imgResponsive" alt="incone4" />
            </div>
          </div>
          <div className={styles.integrationListSubBox}>
            <div className={`${styles.listIcon5} ${styles.listIcon}`}>
              <img src="/assets/images/incone3.svg" alt="icon 1" />
            </div>
            <div className={`${styles.listIcon6} ${styles.listIcon}`}>
              <img src="/assets/images/incone5.svg" alt="ic- 3" />
            </div>
            <div className={`${styles.listItem} `}>
              <img src="/assets/images/int6.png" className="imgResponsive" alt="int6" />
            </div>
            <div className={`${styles.listIcon7} ${styles.listIcon}`}>
              <img src="/assets/images/incone7.svg" alt="int5" />
            </div>
          </div>
        </div>
      </div>
      <TrySaleAssist />
    </section>
  );
};
