const PathConstants = {
    HOME: "/",
    PrivacyPolicy:"/privacy-policy",
    TermsConditions:"/terms-conditions",
    FeaturePage: "/vidsell",
    Reels: "/reels",
    Pricing: "/pricing",
    Strike: "/strike",
    Partner: "/partner",
    AboutUs: "/about-us",
}

export default PathConstants