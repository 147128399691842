import styles from "./ImageModal.module.css"
const ImageModal = ({imageUrl, closeModal}) => {
    return (
        <>
        <div className={styles.galleryModal}>
            <div className={styles.modalBody}>
                <button className={styles.closeModal} onClick={() => closeModal(false)}><svg stroke="currentColor" fill="#fff" strokeWidth="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
                <div className={styles.modalImage}>
                    <img src={`/assets/images/add-ons/${imageUrl}`} alt="imageUrl" />
                </div>
            </div>
        </div>
        <div className={styles.pageOverlay}></div>
        </>
    )
}
export default ImageModal