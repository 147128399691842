import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Marquee from "react-marquee-slider";

export const Carousel = ({ data, direction = "ltr", marginTop = true }) => {
  const [velocity, setVelocity] = useState(isMobile ? 15 : 30);

  return (
    <div
      className={marginTop ? "item-wrap" : "item-wrap1"}
      onMouseOver={() => setVelocity(0)}
      onMouseLeave={() => setVelocity(isMobile ? 15 : 30)}
    >
      <Marquee velocity={velocity} direction={direction}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div className="item" key={index}>
              <img src={item.img} alt="item" className="imgResponsive" />
            </div>
          ))}
      </Marquee>
    </div>
  );
};
