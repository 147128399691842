import React, { useState } from "react";
import ImageModal from "../../../ui/ImageModal/ImageModal";
import styles from "./AddOns.module.css";
export const AddOns = () => {
  const [showModal, setShowModal] = useState(false);
  const [img, setImg] = useState(false);
  const openModal = (img) => {
    setImg(img);
    setShowModal(true);
  };
  const closeModal = () => {
    setImg(false);
    setShowModal(!showModal);
  };
  return (
    <>
      <section className={`section F5F5F5 ${styles.AddOnsMain}`}>
        <div className="container">
          <h2
            className={`font50 poppinsbold lineHeight70 text1919 textCenter ${styles.secHeading}`}
          >
            SaleAssist <span className="HedingBadge">Add-ons</span>
          </h2>
          <div className={styles.AddOnsBox}>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("video-qr.png")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/qrCode.svg"
                    alt="qrcode"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Video QR Code</h4>
                <p>Scan QR code and allow client to do video conversations. Best suited for print media and physical spaces.</p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("video-faq.png")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/faqAddons.svg"
                    alt="faqAddons"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Video FAQ</h4>
                <p>Create AI Videos and assemble them as FAQ for quick understanding. Best suited for website FAQ section.</p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("video-feedback.png")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/liveChat.svg"
                    alt="liveChat"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Video Feedback</h4>
                <p>Capture Video Feedbacks and reuse as website testimonials or social media shoutouts. Best suited for branding activities.</p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("video-emails.png")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/email.svg"
                    alt="email"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Video Emails</h4>
                <p>Record your pesonal video and send personalized video emails to potential clients. Best suited for engaging communication.</p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("shoppable-links.png")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/shopLink.svg"
                    alt="shopLink"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Shoppable Links</h4>
                <p>Host video catalog and send links to customers on whatsapp/SMS etc. Best suited for marketing.</p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
            <div
              className={styles.AddOnsItem}
              onClick={() => openModal("shoppable-video.jpg")}
            >
              <div className={styles.thumbBox}>
                <figure>
                  <img
                    src="/assets/images/videoShop.svg"
                    alt="videoShop"
                    className="imgResponsive"
                  />
                </figure>
              </div>
              <div className={styles.AddOnsContent}>
                <h4>Shoppable Videos</h4>
                <p>Reuse your social media video content to add shopping CTAs. Best for increasing engagement.  </p>
              </div>
              <div className={styles.tryNow}>
                <p className="font14">View details&nbsp; &rarr;</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && <ImageModal imageUrl={img} closeModal={closeModal} />}
    </>
  );
};
