import React from "react";
import data from "../../../data/IndustryLeaders.json";
import data1 from "../../../data/IndustryLeaders1.json";
import { Carousel } from "../../share";
import styles from "./RecognizedBy.module.css";
export const RecognizedBy = () => {
  return (
    <section className={styles.clientListBox}>
      <div className="container">
        <h3 className="smHeading mb20 lineHeight46 poppinsbold textCenter">
          Recognized by Industry leaders
        </h3>
      </div>
      <div className="container">
        <Carousel data={data1} marginTop={false} />
        <Carousel data={data} direction={"rtl"} />
      </div>
    </section>
  );
};
