import { gsap } from "gsap";
import React, { useLayoutEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import faq from "../../../data/faqData.json";
import styles from "./faq.module.css";
export const Faq = () => {
  const [active, setActive] = useState(-1);
  const activeFaq = (id) => {
    active === id ? setActive(-1) : setActive(id);
  };
  const app = useRef([]);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(" .faqBox .faqTitle", {
        scrollTrigger: {
          trigger: ".faqBox ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.2,
        y: 200,
        stagger: 0.25,
      });
      gsap.from(" .faqBox .questionList", {
        scrollTrigger: {
          trigger: ".faqBox ",
        },
        duration: 1,
        opacity: 0,
        delay: 0.5,
        y: 200,
        stagger: 0.25,
      });
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);
  return (
    <section className={`${styles.faqBox} faqBox`} useRef={app}>
      <div className={`container ${styles.faqContainer}`}>
        <div
          className={`faqTitle poppinsbold font50 fontSM26 lineHeightSM34 lineHeight56 text1919 ${styles.faqHeading}`}
        >
          <img
            src="/assets/images/faqIcon.svg"
            className="imgResponsive mb10"
            alt="bg"
          />
          Frequently{!isMobile ? <br /> : " "}
          Asked{!isMobile ? <br /> : " "}
          Questions
        </div>
        <div className="questionList">
          {faq &&
            faq.length > 0 &&
            faq.map((item, index) => (
              <div
                className={`${active === index && styles.active} ${
                  styles.questionBox
                }`}
                onClick={() => activeFaq(index)}
                key={index}
              >
                <p className="font22 fontSM18 text0000 poppinssemibold lineHeight36 lineHeightSM22">
                  {item.question}
                  <span className={styles.icon}></span>
                </p>
                <div className={styles.anwser}>
                  <p
                    className="font16 lineHeight26"
                    dangerouslySetInnerHTML={{ __html: item.ans }}
                  ></p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
