export const SiteLogo = ({varient}) => {
    return (
        <svg width="835" height="133" viewBox="0 0 835 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M239.1 13.35C241.75 16.41 243.1 18.92 243.1 21.71C243.095 22.7213 242.888 23.7213 242.49 24.6511C242.092 25.5808 241.512 26.4215 240.784 27.1234C240.056 27.8253 239.195 28.3742 238.251 28.7378C237.308 29.1013 236.301 29.2721 235.29 29.24C232.36 29.24 229.58 27.43 227.76 25.47C223.86 21.47 220.52 19.2 214.38 19.2C205.88 19.2 201 23.52 201 29.66C201 34.26 203.51 38.44 211.87 41.66L217.31 43.75C239.05 51.98 247.84 59.92 247.84 73.31C247.84 92.54 228.74 102.16 212.57 102.16C201.57 102.16 189.99 97.7 183.16 89.06C181.77 87.38 179.26 84.18 179.26 80C179.26 76.09 181.77 72.05 186.92 72.05C191.1 72.05 193.92 74.7 195.84 77.35C197.827 79.8848 200.367 81.9309 203.267 83.3315C206.167 84.7321 209.35 85.4499 212.57 85.43C219.4 85.43 228.74 82.78 228.74 73.31C228.74 66.06 223.86 62.71 213.13 58.53L207.27 56.3C192.92 50.72 182.32 43.75 182.32 29.54C182.32 12.95 196.26 2.64 213.69 2.64C226 2.62 233.53 7.21999 239.1 13.35Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M334.72 37.32V92.94C334.64 95.3658 333.621 97.6658 331.879 99.3557C330.137 101.046 327.807 101.994 325.38 102C322.961 102.011 320.636 101.064 318.912 99.3667C317.189 97.6693 316.206 95.359 316.18 92.94V90.29C315.07 94.61 307.68 102.14 295.55 102.14C280.22 102.14 261.82 90.29 261.82 64.92C261.82 40.11 280.36 27.84 295.55 27.84C305.45 27.84 313.39 33.14 316.18 39.69V37.32C316.23 34.9085 317.219 32.6116 318.938 30.9192C320.656 29.2267 322.968 28.2725 325.38 28.26C330.26 28.26 334.72 32.44 334.72 37.32ZM317 64.92C317 49.17 306.68 43.46 298.88 43.46C289.68 43.46 280.06 50.7 280.06 64.92C280.06 79.14 289.68 86.39 298.88 86.39C306.7 86.39 317 80.67 317 64.92Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M362.46 101.86C360.041 101.871 357.716 100.924 355.992 99.2267C354.269 97.5293 353.286 95.219 353.26 92.8V10.7C353.26 8.27858 354.222 5.95633 355.934 4.24413C357.646 2.53192 359.969 1.57001 362.39 1.57001C364.811 1.57001 367.134 2.53192 368.846 4.24413C370.558 5.95633 371.52 8.27858 371.52 10.7V92.8C371.499 95.1964 370.538 97.4887 368.843 99.1833C367.149 100.878 364.856 101.839 362.46 101.86Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M460 62C460 69.25 456 71.76 448.57 71.76L407.17 71.48C408.84 81.66 416.92 87.1 427.1 87.1C432.1 87.1 434.49 85.98 438.67 84.17C441.18 83.05 443.13 81.94 445.5 81.94C446.334 81.9016 447.166 82.0374 447.944 82.3389C448.722 82.6404 449.429 83.1009 450.019 83.691C450.609 84.2811 451.07 84.9877 451.371 85.7658C451.673 86.544 451.808 87.3764 451.77 88.21C451.77 90.44 450.77 92.95 447.31 95.88C442.15 99.5 435.18 101.88 426.4 101.88C407.86 101.88 388.21 90.45 388.21 65.08C388.21 39.99 408.98 27.86 424.87 27.86C440.76 27.86 460 40.39 460 62ZM407.17 58.94H442C440.61 48.21 432.81 43.74 424.86 43.74C416.91 43.74 408.45 48.2 407.2 58.93L407.17 58.94Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M560.38 97.12C560.371 97.7333 560.241 98.3388 559.998 98.9019C559.755 99.465 559.403 99.9747 558.963 100.402C558.523 100.829 558.003 101.165 557.433 101.391C556.862 101.618 556.253 101.729 555.64 101.72C554.664 101.685 553.717 101.379 552.905 100.837C552.093 100.295 551.447 99.5377 551.04 98.65L540.31 73.84H493.31L482.6 98.65C482.211 99.5506 481.569 100.32 480.753 100.864C479.937 101.409 478.981 101.706 478 101.72C476.768 101.716 475.586 101.234 474.702 100.376C473.818 99.5181 473.301 98.351 473.26 97.12C473.297 96.3019 473.487 95.498 473.82 94.75L512.15 6.8C513.15 4.57 514.38 3.17 516.89 3.17C519.4 3.17 520.66 4.57 521.63 6.8L559.96 94.8C560.24 95.5412 560.383 96.3275 560.38 97.12ZM537 65.9L516.93 19.48L496.82 65.9H537Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M622.82 39.55C623.59 40.6611 624.028 41.969 624.08 43.32C624.089 43.8431 623.99 44.3624 623.791 44.846C623.592 45.3297 623.296 45.7676 622.921 46.1328C622.547 46.498 622.101 46.7828 621.613 46.9698C621.124 47.1567 620.603 47.2419 620.08 47.22C618.26 47.22 617.01 46.22 615.34 43.73C612.55 39.55 607.53 35.93 599.45 35.93C590.11 35.93 584.53 40.67 584.53 47.5C584.53 52.8 588.16 56.84 596.94 59.07L605.86 61.44C618.86 64.64 627.05 69.94 627.05 80.81C627.05 94.47 613.94 101.86 599.45 101.86C587.88 101.86 577.84 96.7 574.64 89.31C574.08 88.2 572.96 86.67 572.96 84.58C572.96 82.07 574.5 80.26 576.87 80.26C579.24 80.26 580.21 81.51 581.19 83.6C584.11 90.6 591.36 93.5 599.45 93.5C609.2 93.5 617.71 89.59 617.71 81.37C617.71 74.82 612.55 71.75 605.02 69.8L593.87 66.87C580.87 63.53 575.19 56.42 575.19 47.64C575.19 35.93 585.65 27.84 599.59 27.84C610.28 27.84 618.5 32.44 622.82 39.55Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M689.45 39.55C690.213 40.6639 690.647 41.9707 690.7 43.32C690.709 43.8431 690.61 44.3624 690.411 44.846C690.212 45.3297 689.916 45.7676 689.541 46.1328C689.167 46.498 688.721 46.7828 688.233 46.9698C687.744 47.1567 687.223 47.2419 686.7 47.22C684.89 47.22 683.63 46.22 681.96 43.73C679.17 39.55 674.16 35.93 666.07 35.93C656.73 35.93 651.16 40.67 651.16 47.5C651.16 52.8 654.78 56.84 663.56 59.07L672.48 61.44C685.48 64.64 693.67 69.94 693.67 80.81C693.67 94.47 680.57 101.86 666.07 101.86C654.5 101.86 644.46 96.7 641.26 89.31C640.7 88.2 639.59 86.67 639.59 84.58C639.59 82.07 641.12 80.26 643.49 80.26C645.86 80.26 646.83 81.51 647.81 83.6C650.74 90.6 657.99 93.5 666.07 93.5C675.83 93.5 684.33 89.59 684.33 81.37C684.33 74.82 679.17 71.75 671.65 69.8L660.49 66.87C647.49 63.53 641.82 56.42 641.82 47.64C641.82 35.93 652.27 27.84 666.21 27.84C676.9 27.84 685.13 32.44 689.45 39.55Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M709.66 5.12C709.66 4.1544 709.946 3.21051 710.483 2.40778C711.02 1.60505 711.783 0.979578 712.675 0.610518C713.567 0.241458 714.549 0.145401 715.496 0.334509C716.443 0.523617 717.312 0.989384 717.994 1.67287C718.676 2.35635 719.14 3.22682 719.327 4.1741C719.515 5.12139 719.417 6.10291 719.046 6.99444C718.675 7.88596 718.048 8.64742 717.244 9.18243C716.44 9.71745 715.496 10.002 714.53 10C713.242 9.98438 712.01 9.46491 711.1 8.55282C710.19 7.64072 709.673 6.40851 709.66 5.12ZM719.27 33.12V97.12C719.299 97.7601 719.199 98.3996 718.974 98.9997C718.749 99.5998 718.405 100.148 717.963 100.611C717.52 101.075 716.988 101.444 716.399 101.696C715.81 101.948 715.176 102.078 714.535 102.078C713.894 102.078 713.26 101.948 712.671 101.696C712.082 101.444 711.55 101.075 711.107 100.611C710.665 100.148 710.321 99.5998 710.096 98.9997C709.871 98.3996 709.771 97.7601 709.8 97.12V33.12C709.771 32.4799 709.871 31.8404 710.096 31.2403C710.321 30.6402 710.665 30.0919 711.107 29.6285C711.55 29.1651 712.082 28.7963 712.671 28.5443C713.26 28.2923 713.894 28.1623 714.535 28.1623C715.176 28.1623 715.81 28.2923 716.399 28.5443C716.988 28.7963 717.52 29.1651 717.963 29.6285C718.405 30.0919 718.749 30.6402 718.974 31.2403C719.199 31.8404 719.299 32.4799 719.27 33.12Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M785.34 39.55C786.11 40.6611 786.547 41.969 786.6 43.32C786.608 43.8471 786.507 44.3701 786.304 44.8566C786.101 45.3431 785.801 45.7828 785.421 46.1485C785.041 46.5141 784.591 46.7978 784.097 46.9822C783.603 47.1665 783.076 47.2474 782.55 47.22C780.74 47.22 779.49 46.22 777.81 43.73C775.03 39.55 770.01 35.93 761.92 35.93C752.58 35.93 747.01 40.67 747.01 47.5C747.01 52.8 750.63 56.84 759.41 59.07L768.34 61.44C781.34 64.64 789.52 69.94 789.52 80.81C789.52 94.47 776.42 101.86 761.92 101.86C750.35 101.86 740.32 96.7 737.11 89.31C736.56 88.2 735.44 86.67 735.44 84.58C735.44 82.07 736.97 80.26 739.34 80.26C741.71 80.26 742.69 81.51 743.66 83.6C746.59 90.6 753.84 93.5 761.92 93.5C771.68 93.5 780.18 89.59 780.18 81.37C780.18 74.82 775.03 71.75 767.5 69.8L756.35 66.87C743.35 63.53 737.67 56.42 737.67 47.64C737.67 35.93 748.12 27.84 762.06 27.84C772.8 27.84 781 32.44 785.34 39.55Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M834.82 95.87C834.82 100.47 827.82 101.87 822.56 101.87C815.87 101.87 809.45 99.36 809.45 88.07V36.77H802.62C801.549 36.77 800.521 36.3444 799.763 35.5867C799.006 34.8291 798.58 33.8015 798.58 32.73C798.58 31.6585 799.006 30.6309 799.763 29.8733C800.521 29.1156 801.549 28.69 802.62 28.69H809.45V16C809.45 14.7429 809.949 13.5372 810.838 12.6483C811.727 11.7594 812.933 11.26 814.19 11.26C815.447 11.26 816.653 11.7594 817.542 12.6483C818.431 13.5372 818.93 14.7429 818.93 16V28.68H830.64C831.711 28.68 832.739 29.1056 833.497 29.8633C834.254 30.6209 834.68 31.6485 834.68 32.72C834.68 33.7915 834.254 34.8191 833.497 35.5767C832.739 36.3344 831.711 36.76 830.64 36.76H818.93V87.5C818.93 91.96 820.93 93.64 824.51 93.64C826.405 93.5737 828.28 93.237 830.08 92.64C830.606 92.4171 831.179 92.3298 831.747 92.386C832.315 92.4421 832.86 92.64 833.331 92.9615C833.803 93.2829 834.187 93.7177 834.447 94.2259C834.707 94.734 834.835 95.2993 834.82 95.87Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
            <path d="M141.18 25C141.11 18.9 136.77 16.45 131.5 19.44C126.88 22.06 122.28 24.73 117.66 27.36C116.84 27.82 115.96 28.17 114.66 28.78C114.59 27.36 114.49 26.41 114.49 25.46C114.49 21.22 114.49 16.99 114.49 12.76C114.558 11.2949 114.059 9.86 113.096 8.75369C112.133 7.64737 110.78 6.9548 109.32 6.82C108.064 6.70153 106.801 6.66812 105.54 6.72H11.9C10.34 6.72 8.89998 6.72 7.49998 6.72C2.80998 6.79 0.559988 9.01 0.549988 13.72C0.549988 50.94 0.549988 88.16 0.549988 125.38C0.549988 128.26 1.18998 130.8 4.08998 132.1C6.98998 133.4 9.08999 132.1 11.21 130.33C22.07 121.4 32.97 112.53 43.81 103.57C45.2149 102.324 47.043 101.662 48.92 101.72C68.48 101.78 88.0366 101.78 107.59 101.72C112.44 101.72 114.46 99.72 114.52 94.84C114.52 92.23 114.52 89.61 114.52 87V79.53C115.96 80.24 116.84 80.62 117.65 81.09C122.18 83.7 126.65 86.36 131.21 88.96C136.94 92.24 141.21 89.75 141.21 83.08C141.21 72.27 141.21 61.45 141.21 50.64C141.22 42.1 141.28 33.54 141.18 25ZM99.79 90.15C81.86 89.98 63.94 89.97 46 89.81C43.2026 89.7239 40.4777 90.7074 38.38 92.56C30.86 98.87 23.2 105.04 15.6 111.26C14.85 111.86 14.08 112.43 13.31 113.01L12.56 112.7V109.26C12.56 82.94 12.56 56.6067 12.56 30.26C12.56 27.39 12.38 24.51 12.11 21.65C11.86 19.12 12.66 18.18 15.36 18.2C34.91 18.3 54.47 18.2 74.02 18.2C82.68 18.2 91.33 18.27 100.02 18.14C102.41 18.14 103.09 18.87 103.09 21.23C103.01 43.13 103.01 65.03 103.09 86.93C103.05 89.58 102.2 90.16 99.79 90.13V90.15ZM129.42 74.31C128.21 73.71 127.32 73.31 126.52 72.86C123.36 70.97 120.27 68.96 117.07 67.15C116.383 66.8415 115.807 66.3303 115.419 65.685C115.031 65.0397 114.85 64.2912 114.9 63.54C114.9 57.54 114.76 51.54 114.48 45.54C114.343 44.6603 114.497 43.76 114.917 42.9752C115.338 42.1904 116.002 41.5638 116.81 41.19C120.89 38.98 124.86 36.56 129.42 33.91V74.31Z" fill="#FEDF29"/>
            <path d="M76 50.47L49.51 35.17C48.813 34.7675 48.022 34.5567 47.2172 34.5589C46.4124 34.5611 45.6225 34.7763 44.9278 35.1826C44.233 35.5889 43.6582 36.1718 43.2617 36.8721C42.8651 37.5724 42.661 38.3652 42.67 39.17V69.76C42.6698 70.5605 42.8804 71.347 43.2806 72.0403C43.6807 72.7337 44.2564 73.3094 44.9496 73.7098C45.6428 74.1101 46.4292 74.3209 47.2298 74.3209C48.0303 74.321 48.8167 74.1103 49.51 73.71L76 58.37C76.6936 57.9699 77.2697 57.3942 77.6702 56.7008C78.0707 56.0074 78.2816 55.2208 78.2816 54.42C78.2816 53.6192 78.0707 52.8326 77.6702 52.1392C77.2697 51.4458 76.6936 50.8701 76 50.47Z" fill={(varient === 'light') ? '#fff' : '#0e0d12'} />
        </svg>
    )
}