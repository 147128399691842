import React from "react";
import ClientList from "../../../data/overClients.json";
import { Carousel } from "../../share";
export const OverClients = () => {
  return (
    <>
      <section className="clientListBox section">
        <h3 className="smHeading mb20 mbSM0 lineHeight46 poppinsbold textCenter">
          Over 300 clients with $500m+ in live sales
        </h3>
        <Carousel data={ClientList} />
        <p className={`textCenter font20 fontSM16 poppinsbold `}>
          and many more…
        </p>
      </section>
    </>
  );
};
