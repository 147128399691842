import React from 'react';
import { Faq } from '../share';
import { AddOns, Integrations, NumberSays, OverClients, RecognizedBy, ReImage, TopBanner } from './';
import CommerceAiSec from './CommerceAiSec/CommerceAiSec';
import FeatureSec from './FeatureSec/FeatureSec';
import TestimonialSec from './TestimonialSec/TestimonialSec';
export const HomePage = () => {


  return (
    <>
      <TopBanner/>      
      <OverClients/>
      <ReImage/>
      <FeatureSec />
      <Integrations/>
      <CommerceAiSec />
      <AddOns />
      <TestimonialSec />
      <RecognizedBy/>
      <NumberSays/>
      <Faq/>
    </>
  )
}
